import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import { GiMoneyStack, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { GlobalContext } from "../../Context/GlobalState";
import { RxComponent1 } from "react-icons/rx";

import { Collapse } from "react-bootstrap";
import {
  MdPeople,
  MdCheckCircle,
  MdLockOpen,
  MdAttachMoney,
  MdAccountBalanceWallet,
  MdPerson,
  MdCloudDownload,
  MdAddIcCall,
  MdInventory,
  MdDataset,
  MdDashboardCustomize,
} from "react-icons/md";

import {
  FaChartPie,
  FaClock,
  FaMoneyBill,
  FaCalendarAlt,
  FaSignInAlt,
  FaPlusCircle,
  FaMapMarkerAlt,
  FaTable,
  FaRegFilePdf,
  FaReceipt,
  FaWpforms,
  FaTasks,
  FaUserSlash,
  FaRegNewspaper,
  FaChevronDown,
  FaUserCircle,
} from "react-icons/fa";
import {
  COMPANY_NAME,
  COMPANY_NAME_NAVBAR,
  OFFLINE_TEXT,
} from "../../utils/StringConstants";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_API_TRANXT_UAT_API,
} from "../../utils/Constants";
import { IoIosAddCircle, IoIosContacts, IoMdSettings } from "react-icons/io";
import { CiReceipt } from "react-icons/ci";
import { HiCurrencyRupee } from "react-icons/hi";
import { TbTemplate } from "react-icons/tb";
import { IoDocumentText, IoDocumentTextOutline } from "react-icons/io5";
import { RiArticleLine } from "react-icons/ri";
const style = {};
const activeStyle = {};

class Sidebar extends Component {
  state = {
    services: [],
    verifiedKycData: null,
    user: null,
    accessData: null,
    accessDataemp: null,
    isMounted: false,
    isLoading: false,
    isSubmenuOpen: false,
  };
  toggleSubmenu = () => {
    this.setState((prevState) => ({
      isSubmenuOpen: !prevState.isSubmenuOpen,
    }));
  };
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ ...this.state, [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ ...this.state, [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ ...this.state, [i]: false });
      });
      this.setState({ ...this.state, [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    // this.accessInfo();
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    // Object.keys(this.state).forEach((i) => {
    //   this.setState({ [i]: false });
    // });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/report", state: "ReportMenuOpen" },
      { path: "/dmt", state: "DMTMenuOpen" },
      { path: "/offline", state: "HybridMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ ...this.state, [obj.state]: true });
      }
    });
  }
  //  Main Code
  accessInfo() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const user = JSON.parse(sessionStorage.getItem("user"));
    this.setState({ ...this.state, user: user });

    var raw = JSON.stringify({
      userid: user?.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      // `${BASE_URL_API_TRANXT_API}/api/user/get_user_access_info.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ ...this.state, services: result.services });
      })
      .catch((error) => console.log("error", error));
  }

  // END Main Code

  verificationData() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const user = JSON.parse(sessionStorage.getItem("user"));

      var raw = JSON.stringify({
        userid: user.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data.aspx`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status == "0") {
            this.setState({ ...this.state, verifiedKycData: data.data });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {}
  }

  isServiceActive(idx) {
    let active = false;
    if (this.state.services) {
      this.state.services.forEach((menu) => {
        if (menu.pk_menu_id == idx && menu.status == "Y") {
          active = true;
        }
      });
    }
    return active;
  }

  static contextType = GlobalContext;
  componentWillUnmount() {
    // Reset mounted flag to false when component unmounts
    this.setState({ isMounted: false });
  }

  fetchAccessData = async () => {
    this.setState({ isLoading: true });
    const { user, setAccessData } = this.context;
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firmid: user.firm_id,
          empid: user.userid,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAccessManagementSuperAdmin`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setAccessData(data.data);
      }
    } catch (error) {
      console.error("Error fetching access data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  async fetchAccessDataemployee() {
    this.setState({ isLoading: true });
    const { user, setAccessemployeData } = this.context;

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firmid: user.firm_id,
          empid: user.userid,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAccessManagementAdmin`,
        requestOptions
      );
      const data = await response.json();
      console.log("Fetched data:", data);
      if (data.status === "0") {
        if (this.state.isMounted) {
          setAccessemployeData(data.data);
          this.setState({ accessDataemp: data.data });
        }
      }
    } catch (error) {
      console.error("Error fetching access data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading, accessDataemp } = this.state;
    if (isLoading) {
      return <div>Loading...</div>;
    }
    const { accessData } = this.context;
    const { isSubmenuOpen } = this.state;

    return (
      <nav
        style={{
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="sidebar sidebar-offcanvas"
        id="sidebar"
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <strong style={{ color: "#FFF" }}>{COMPANY_NAME_NAVBAR}</strong>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            {/* <img
              src={require("../../../assets/images/company/logo_square.png")}
              alt="logo"
            /> */}
          </a>
        </div>
        <ul
          className="nav mt-2"
          style={{ overflowY: "scroll", height: "100vh" }}
        >
          {/* Super Admin */}
          {this.state.user?.us_type == "S" && (
            <React.Fragment>
              <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
                <strong className="text-light">Super Admin System</strong>
              </div>
              <li
                style={
                  (this.isPathActive("/retailer/Superadmin_dashboard")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Superadmin_dashboard")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/Superadmin_dashboard"}
                >
                  <span>
                    <FaChartPie
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans> Dashboard</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Reacent_queries")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Reacent_queries")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Reacent_queries"}>
                  <span>
                    <IoIosContacts
                      style={{
                        color: "green",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Recent Queries </Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Login_queries")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Login_queries")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Login_queries"}>
                  <span>
                    <MdAddIcCall
                      style={{
                        color: "#7469B6",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Login Queries </Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/retailer/Custom_Blogs") ||
                  this.isPathActive("/retailer/Blogs")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
                style={{ marginTop: "10px" }}
              >
                <div className="nav-link" onClick={this.toggleSubmenu}>
                  <span>
                    <FaRegNewspaper
                      style={{
                        color: "#FF9100",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Blogs</Trans>
                  </span>
                  <span style={{ marginLeft: "auto" }}>
                    <FaChevronDown
                      style={{
                        fontSize: "1.2rem",
                        transition: "transform 0.3s ease",
                        transform: isSubmenuOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </span>
                </div>

                <ul
                  className="submenu"
                  style={{ display: isSubmenuOpen ? "block" : "none" }}
                >
                  <li
                    style={{ marginTop: "10px" }}
                    className={
                      this.isPathActive("/retailer/Custom_Blogs")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to={"/retailer/Custom_Blogs"}>
                      <span>
                        <IoIosAddCircle
                          style={{
                            color: "#41B06E",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span className="menu-title">
                        <Trans>Add New Blog</Trans>
                      </span>
                    </Link>
                  </li>

                  <li
                    style={{ marginTop: "10px" }}
                    className={
                      this.isPathActive("/retailer/Blogs")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to={"/retailer/Blogs"}>
                      <span>
                        <RiArticleLine
                          style={{
                            color: "#0D92F4",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span className="menu-title">
                        <Trans>Edit Blogs</Trans>
                      </span>
                    </Link>
                  </li>
                  <li
                    style={{ marginTop: "10px" }}
                    className={
                      this.isPathActive("/retailer/Blog_Author")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to={"/retailer/Blog_Author"}>
                      <span>
                        <FaUserCircle
                          style={{
                            color: "#B7E0FF",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span className="menu-title">
                        <Trans>Author</Trans>
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                style={
                  (this.isPathActive("/retailer/Super_admin_setting")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Super_admin_setting")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/Super_admin_setting"}
                >
                  <span>
                    <IoMdSettings
                      style={{
                        color: "yellow",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Settings </Trans>
                  </span>
                </Link>
              </li>
            </React.Fragment>
          )}
          {/* Admin */}

          {this.state.user?.us_type == "A" && (
            <React.Fragment>
              <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
                <strong className="text-light">Admin System</strong>
              </div>
              <li
                style={
                  (this.isPathActive("/retailer/AdminDashboard")
                    ? activeStyle
                    : style,
                  { marginTop: "0px"

                   })
                }
                className={
                  this.isPathActive("/retailer/AdminDashboard")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/AdminDashboard"}>
                  <span>
                    <FaChartPie
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}> 
                    <Trans >Admin Dashboard</Trans>
                  </span>
                </Link>
              </li>
              {/* <li
                style={
                  (this.isPathActive("/retailer/Custom_Blogs1")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Custom_Blogs1")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Custom_Blogs1"}>
                  <span>
                    <MdCloudDownload
                      style={{ color: "#41B06E", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>test Blogs  </Trans>
                  </span>
                </Link>
              </li> */}

              {/* <li
                style={
                  (this.isPathActive("/retailer/LoadRequest")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/LoadRequest")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/LoadRequest"}>
                  <span>
                    <MdCloudDownload
                      style={{ color: "#41B06E", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Wallet Topup  </Trans>
                  </span>
                </Link>
              </li> */}
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Employee Management" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/EmployeeListAdmin")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/EmployeeListAdmin")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/EmployeeListAdmin"}
                            >
                              <span>
                                <MdPeople
                                  style={{
                                    color: "#FFC300",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Employee Management</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdPeople
                                style={{
                                  color: "#FFC300",
                                  marginRight: "1rem",
                                }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Employee Management</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}

              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Salary Management" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Salary_management")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Salary_management")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Salary_management"}
                            >
                              <span>
                                <FaChartPie
                                  style={{ color: "Plum", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Salary Management </Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaChartPie
                                style={{ color: "Plum", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Salary Management </Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}

              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Attendance Management" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive(
                                "/retailer/AttandancecalendraAdmin"
                              )
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive(
                                "/retailer/AttandancecalendraAdmin"
                              )
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/AttandancecalendraAdmin"}
                            >
                              <span>
                                <FaClock
                                  style={{
                                    color: "Turquoise",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Attendance Management </Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaClock
                                style={{
                                  color: "Turquoise",
                                  marginRight: "1rem",
                                }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Attendance Management </Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
                  <li
                style={
                  (this.isPathActive("/retailer/Createproject")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Createproject")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Createproject"}>
                  <span>
                    <FaPlusCircle
                      style={{ color: "yellow", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Projects</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Vendor_Management")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Vendor_Management")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Vendor_Management"}>
                  <span>
                    <MdInventory
                      style={{ color: "#41B06E", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Vendor Management</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Budget_analysis")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Budget_analysis")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Budget_analysis"}>
                  <span>
                    <MdDashboardCustomize
                      style={{ color: "#8B5DFF", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Budget Analysis</Trans>
                  </span>
                </Link>
              </li>
            
              <li
                style={
                  (this.isPathActive("/retailer/ExpenseDashboardAdmin")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/ExpenseDashboardAdmin")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/ExpenseDashboardAdmin"}
                >
                  <span>
                    <FaMoneyBill
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Expense Management</Trans>
                  </span>
                </Link>
              </li>
            
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Payroll" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive(
                                "/retailer/Payrollprocesssalary"
                              )
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive(
                                "/retailer/Payrollprocesssalary"
                              )
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Payrollprocesssalary"}
                            >
                              <span>
                                <MdAttachMoney
                                  style={{
                                    color: "yellow",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Payroll Management</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdAttachMoney
                                style={{ color: "yellow", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Payroll Management</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Expense Payroll" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Expensepayroll")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Expensepayroll")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Expensepayroll"}
                            >
                              <span>
                                <FaPlusCircle
                                  style={{
                                    color: "orange",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Expense Payroll </Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaPlusCircle
                                style={{ color: "orange", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Expense Payroll </Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}

              <li
                style={
                  (this.isPathActive("/retailer/Arrear_salary")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Arrear_salary")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Arrear_salary"}>
                  <span>
                    <GiMoneyStack
                      style={{ color: "green", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Arrear</Trans>
                  </span>
                </Link>
              </li>
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Ledger" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Accountledger")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Accountledger")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Accountledger"}
                            >
                              <span>
                                <FaTable
                                  style={{
                                    color: "#97E7E1",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Account Ledger</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdAttachMoney
                                style={{ color: "yellow", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Account Ledger</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
  <li
                style={
                  (this.isPathActive("/retailer/Office_inventory")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Office_inventory")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Office_inventory"}>
                  <span>
                    <MdInventory
                      style={{ color: "#41B06E", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Office Inventory</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Balance_leave")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Balance_leave")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Balance_leave"}>
                  <span>
                    <FaChartPie
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Leave Management</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/LeaveApprovalAdmin")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/LeaveApprovalAdmin")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/LeaveApprovalAdmin"}
                >
                  <span>
                    <MdCheckCircle
                      style={{ color: "pink", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Leave Approval</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/EmployeeLeave")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/EmployeeLeave")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/EmployeeLeave"}>
                  <span>
                    <MdCheckCircle
                      style={{ color: "#DAF7A6", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Add Leave/WFH</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/PayrollAdminAccess")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/PayrollAdminAccess")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/PayrollAdminAccess"}
                >
                  <span>
                    <MdLockOpen
                      style={{ color: "yellow", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Access </Trans>
                  </span>
                </Link>
              </li>
            
              <li
                style={
                  (this.isPathActive("/retailer/Create_workspace_admin")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Create_workspace_admin")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/Create_workspace_admin"}
                >
                  <span>
                    <FaPlusCircle
                      style={{ color: "green", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Create Workspace</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Task_Overview")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Task_Overview")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Task_Overview"}>
                  <span>
                    <FaTasks style={{ color: "pink", marginRight: "1rem" }} />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Task Overview</Trans>
                  </span>
                </Link>
              </li>

              {/* <li
                style={
                  (this.isPathActive("/retailer/Tax") ? activeStyle : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Tax")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Tax"}>
                  <span>
                    <FaReceipt
                      style={{
                        color: "#7469B6",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Taxes </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Form16") ? activeStyle : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Form16")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Form16"}>
                  <span>
                    <FaWpforms
                      style={{
                        color: "green",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Form 16 </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Statutory_components")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Statutory_components")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/Statutory_components"}
                >
                  <span>
                    <HiCurrencyRupee
                      style={{
                        color: "#FFA27F",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Statutory Components </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Salary_components")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Salary_components")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Salary_components"}>
                  <span>
                    <RxComponent1
                      style={{
                        color: "#83B4FF",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Salary Components </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Salary_template")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Salary_template")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Salary_template"}>
                  <span>
                    <TbTemplate
                      style={{
                        color: "#FF6969",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Salary Template </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Loan") ? activeStyle : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Loan")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Loan"}>
                  <span>
                    <GiPayMoney
                      style={{
                        color: "#ACD793",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Loan </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Admin_Investment")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Admin_Investment")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Admin_Investment"}>
                  <span>
                    <IoDocumentText
                      style={{
                        color: "#fff",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Investment </Trans>
                  </span>
                </Link>
              </li> */}

              <li
                style={
                  (this.isPathActive("/retailer/Privacypolicy")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Privacypolicy")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Privacypolicy"}>
                  <span>
                    <FaRegFilePdf
                      style={{ color: "red", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Policy </Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Inactive_Employee")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Inactive_Employee")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Inactive_Employee"}>
                  <span>
                    <FaUserSlash
                      style={{ color: "#96C9F4", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Inactive Employee </Trans>
                  </span>
                </Link>
              </li>

              <li
                style={
                  (this.isPathActive("/retailer/Admin_Setting")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Admin_Setting")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Admin_Setting"}>
                  <span>
                    <IoMdSettings
                      style={{ color: "yellow", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Settings </Trans>
                  </span>
                </Link>
              </li>
            </React.Fragment>
          )}

          {/* HR */}

          {this.state.user?.us_type == "H" && (
            <React.Fragment>
              <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
                <strong className="text-light">HR System</strong>
              </div>
              <li
                style={
                  (this.isPathActive("/retailer/HRDashboard")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/HRDashboard")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/HRDashboard"}>
                  <span>
                    <FaChartPie
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>HR Dashboard</Trans>
                  </span>
                </Link>
              </li>
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Employee Management" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/EmployeelistHR")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/EmployeelistHR")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/EmployeelistHR"}
                            >
                              <span>
                                <MdPeople
                                  style={{
                                    color: "#FFC300",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Employee Management</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdPeople
                                style={{
                                  color: "#FFC300",
                                  marginRight: "1rem",
                                }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Employee Management</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Salary Management" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/SalaryManagement")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/SalaryManagement")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/SalaryManagement"}
                            >
                              <span>
                                <FaChartPie
                                  style={{ color: "Plum", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Salary Management </Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaChartPie
                                style={{ color: "Plum", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Salary Management </Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Attendance Management" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive(
                                "/retailer/AttandancecalendraHR"
                              )
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive(
                                "/retailer/AttandancecalendraHR"
                              )
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/AttandancecalendraHR"}
                            >
                              <span>
                                <FaClock
                                  style={{
                                    color: "Turquoise",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Attendance Management </Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaClock
                                style={{
                                  color: "Turquoise",
                                  marginRight: "1rem",
                                }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Attendance Management </Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              <li
                style={
                  (this.isPathActive("/retailer/Office_inventory")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Office_inventory")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Office_inventory"}>
                  <span>
                    <MdInventory
                      style={{ color: "#41B06E", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Office Inventory</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Balance_leave_hr")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Balance_leave_hr")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Balance_leave_hr"}>
                  <span>
                    <FaChartPie
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Leave Management</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/LeaveApprovalHr")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/LeaveApprovalHr")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/LeaveApprovalHr"}>
                  <span>
                    <MdCheckCircle
                      style={{ color: "pink", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Leave Approval</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/EmployeeLeave")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/EmployeeLeave")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/EmployeeLeave"}>
                  <span>
                    <MdCheckCircle
                      style={{ color: "#DAF7A6", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Add Leave/WFH</Trans>
                  </span>
                </Link>
              </li>
              {/* <li
                style={
                  (this.isPathActive("/retailer/Tax") ? activeStyle : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Tax")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Tax"}>
                  <span>
                    <FaReceipt
                      style={{
                        color: "#7469B6",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Taxes </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Form16") ? activeStyle : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Form16")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Form16"}>
                  <span>
                    <FaWpforms
                      style={{
                        color: "green",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Form 16 </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Statutory_components")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Statutory_components")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/Statutory_components"}
                >
                  <span>
                    <HiCurrencyRupee
                      style={{
                        color: "#FFA27F",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Statutory Components </Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Salary_components")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Salary_components")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Salary_components"}>
                  <span>
                    <RxComponent1
                      style={{
                        color: "#83B4FF",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Salary Components </Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Salary_template")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Salary_template")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Salary_template"}>
                  <span>
                    <TbTemplate
                      style={{
                        color: "#FF6969",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Salary Template </Trans>
                  </span>
                </Link>
              </li> */}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Loan") ? activeStyle : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Loan")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Loan"}>
                  <span>
                    <GiPayMoney
                      style={{
                        color: "#ACD793",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Loan </Trans>
                  </span>
                </Link>
              </li> */}
              <li
                style={
                  (this.isPathActive("/retailer/ExpenseDashboardHR")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/ExpenseDashboardHR")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/ExpenseDashboardHR"}
                >
                  <span>
                    <FaMoneyBill
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Expense Management</Trans>
                  </span>
                </Link>
              </li>
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Payroll" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive(
                                "/retailer/Payrollprocesssalaryhr"
                              )
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive(
                                "/retailer/Payrollprocesssalaryhr"
                              )
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Payrollprocesssalaryhr"}
                            >
                              <span>
                                <MdAttachMoney
                                  style={{
                                    color: "yellow",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Payroll Management</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdAttachMoney
                                style={{ color: "yellow", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Payroll Management</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Expense Payroll" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Expensepayrollhr")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Expensepayrollhr")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Expensepayrollhr"}
                            >
                              <span>
                                <FaPlusCircle
                                  style={{
                                    color: "orange",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Expense Payroll </Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaPlusCircle
                                style={{ color: "orange", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Expense Payroll </Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              <li
                style={
                  (this.isPathActive("/retailer/Arrear_salary_hr")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Arrear_salary_hr")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Arrear_salary_hr"}>
                  <span>
                    <GiMoneyStack
                      style={{ color: "green", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Generate Arrear</Trans>
                  </span>
                </Link>
              </li>
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Ledger" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/AccountledgerHR")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/AccountledgerHR")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/AccountledgerHR"}
                            >
                              <span>
                                <FaTable
                                  style={{
                                    color: "#97E7E1",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Account Ledger</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdAttachMoney
                                style={{ color: "yellow", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans> Account Ledger</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}

              <li
                style={
                  (this.isPathActive("/retailer/Privacypolicy")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Privacypolicy")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Privacypolicy"}>
                  <span>
                    <FaRegFilePdf
                      style={{ color: "red", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Policy </Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Inactive_Employee")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Inactive_Employee")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Inactive_Employee"}>
                  <span>
                    <FaUserSlash
                      style={{ color: "#96C9F4", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Inactive Employee </Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Admin_Setting")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Admin_Setting")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Admin_Setting"}>
                  <span>
                    <IoMdSettings
                      style={{ color: "yellow", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Settings </Trans>
                  </span>
                </Link>
              </li>
            </React.Fragment>
          )}

          {/* Employee */}

          {this.state.user?.us_type == "E" && (
            <React.Fragment>
              <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
                <strong className="text-light">Employee System</strong>
              </div>
              <li
                style={
                  (this.isPathActive("/retailer/EmployeeDashboard")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/EmployeeDashboard")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/EmployeeDashboard"}>
                  <span>
                    <FaChartPie
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Employee Dashboard</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Salaryslip")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Salaryslip")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Salaryslip"}>
                  <span>
                    <FaReceipt
                      style={{ color: "#FEB941", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Salary Slip</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/TrackingEmployee")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/TrackingEmployee")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/TrackingEmployee"}>
                  <span>
                    <FaMapMarkerAlt
                      style={{ color: "green", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Expense Tracking</Trans>
                  </span>
                </Link>
              </li>
              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Profile" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive(
                                "/retailer/ViewEmployeeprofile"
                              )
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/ViewEmployeeprofile")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/ViewEmployeeprofile"}
                            >
                              <span>
                                <MdPerson
                                  style={{ color: "Aqua", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Profile</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdPeople
                                style={{
                                  color: "#FFC300",
                                  marginRight: "1rem",
                                }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Profile</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}

              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Attendance" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/EmployeeAttandance")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/EmployeeAttandance")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/EmployeeAttandance"}
                            >
                              <span>
                                <FaCalendarAlt
                                  style={{
                                    color: "yellow",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Attendance Management</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaCalendarAlt
                                style={{ color: "yellow", marginRight: "" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Attendance Management</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Office_inventory")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Office_inventory")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Office_inventory"}>
                  <span>
                    <MdInventory
                      style={{ color: "#41B06E", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Office Inventory</Trans>
                  </span>
                </Link>
              </li> */}
              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Inventory Management" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Office_inventory")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Office_inventory")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Office_inventory"}
                            >
                              <span>
                                <MdInventory
                                  style={{
                                    color: "#41B06E",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Office Inventory</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <MdInventory
                                style={{
                                  color: "#41B06E",
                                  marginRight: "1rem",
                                }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Office Inventory</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Leave Approval" ? (
                        role.access === "Y" ? (
                          <li
                            style={
                              (this.isPathActive("/retailer/LeaveApprovalAdmin")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/LeaveApprovalAdmin")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/LeaveApprovalAdmin"}
                            >
                              <span>
                                <MdCheckCircle
                                  style={{ color: "pink", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title" style={{fontSize:".8rem"}}>
                                <Trans>Leave Approval</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <span>
                                <MdCheckCircle
                                  style={{ color: "pink", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title" style={{fontSize:".8rem"}}>
                                <Trans>Leave Approval</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              <li
                style={
                  (this.isPathActive("/retailer/ExpenseDashboardemployee")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/ExpenseDashboardemployee")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/ExpenseDashboardemployee"}
                >
                  <span>
                    <FaMoneyBill
                      style={{ color: "Plum", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Expense Management</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/EmployeeLeave")
                    ? activeStyle
                    : style,
                  { marginTop: " 0px" })
                }
                className={
                  this.isPathActive("/retailer/EmployeeLeave")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/EmployeeLeave"}>
                  <span>
                    <MdCheckCircle
                      style={{ color: "#DAF7A6", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Add Leave/WFH</Trans>
                  </span>
                </Link>
              </li>
              {/* <li
                style={
                  (this.isPathActive("/retailer/Employee_Balance_Leave")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Employee_Balance_Leave")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/Employee_Balance_Leave"}
                >
                  <span>
                    <MdDataset
                      style={{ color: "#41B06E", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Balance Leave</Trans>
                  </span>
                </Link>
              </li> */}
              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Create Workspace" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Createworkemployee")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Createworkemployee")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Createworkemployee"}
                            >
                              <span>
                                <FaPlusCircle
                                  style={{
                                    color: "orange",
                                    marginRight: "1rem",
                                  }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Create Work Space</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaPlusCircle
                                style={{ color: "orange", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Create Work Space</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Createworkemployee")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Createworkemployee")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link
                  className={`nav-link`}
                  to={"/retailer/Createworkemployee"}
                >
                  <span>
                    <FaPlusCircle
                      style={{ color: "orange", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Create Work Space</Trans>
                  </span>
                </Link>
              </li> */}
              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Workspace" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/WorkDashboardnew")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/WorkDashboardnew")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/WorkDashboardnew"}
                            >
                              <span>
                                <FaChartPie
                                  style={{ color: "Plum", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Work Dashboard </Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaChartPie
                                style={{ color: "Plum", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Work Dashboard </Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {/* <li
                style={
                  (this.isPathActive("/retailer/Task_Overview")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Task_Overview")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Task_Overview"}>
                  <span>
                    <FaTasks
                      style={{ color: "pink", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Task Overview</Trans>
                  </span>
                </Link>
              </li> */}

              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Task Overview" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Task_Overview")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Task_Overview")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Task_Overview"}
                            >
                              <span>
                                <FaTasks
                                  style={{ color: "pink", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Task Overview</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaTasks
                                style={{ color: "pink", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Task Overview</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}
              {accessDataemp &&
                accessDataemp.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "View Policy" ? (
                        role.access === "Y" ? (
                          <li
                            key={role.pk_role_id}
                            style={
                              (this.isPathActive("/retailer/Privacy_policy")
                                ? activeStyle
                                : style,
                              { marginTop: "0px" })
                            }
                            className={
                              this.isPathActive("/retailer/Privacy_policy")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className={`nav-link`}
                              to={"/retailer/Privacy_policy"}
                            >
                              <span>
                                <FaRegFilePdf
                                  style={{ color: "red", marginRight: "1rem" }}
                                />
                              </span>
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Policy</Trans>
                              </span>
                            </Link>
                          </li>
                        ) : (
                          <li
                            key={role.pk_role_id}
                            style={{ marginTop: "0px" }}
                            className="nav-item menu-items px-md-3"
                          >
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                alignItems: "center",
                                fontSize: ".8rem",
                                backgroundColor: "#212529",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaRegFilePdf
                                style={{ color: "red", marginRight: "1rem" }}
                              />
                              <span className="menu-title " style={{fontSize:".8rem"}}>
                                <Trans>Policy</Trans>
                              </span>
                            </button>
                          </li>
                        )
                      ) : null
                    )
                )}

              <li
                style={
                  (this.isPathActive("/retailer/EPFcalculator")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/EPFcalculator")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/EPFcalculator"}>
                  <span>
                    <MdAccountBalanceWallet
                      style={{ color: "pink", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>EPF</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Investment")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Investment")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Investment"}>
                  <span>
                    <IoDocumentText
                      style={{ color: "#fff", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans>Investment</Trans>
                  </span>
                </Link>
              </li>
              <li
                style={
                  (this.isPathActive("/retailer/Employee_Settings")
                    ? activeStyle
                    : style,
                  { marginTop: "0px" })
                }
                className={
                  this.isPathActive("/retailer/Employee_Settings")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Employee_Settings"}>
                  <span>
                    <IoMdSettings
                      style={{ color: "yellow", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title" style={{fontSize:".8rem"}}>
                    <Trans> Settings </Trans>
                  </span>
                </Link>
              </li>
              {/* <li
                style={
                  (this.isPathActive("/retailer/Dummy")
                    ? activeStyle
                    : style,
                  { marginTop: "10px" })
                }
                className={
                  this.isPathActive("/retailer/Dummy")
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
              >
                <Link className={`nav-link`} to={"/retailer/Dummy"}>
                  <span>
                    <MdAccountBalanceWallet
                      style={{ color: "pink", marginRight: "1rem" }}
                    />
                  </span>
                  <span className="menu-title">
                    <Trans>Task</Trans>
                  </span>
                </Link>
              </li> */}
            </React.Fragment>
          )}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.accessInfo();
    this.fetchAccessData();
    this.fetchAccessDataemployee();
    this.onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    // Set mounted flag to true
    this.setState({ isMounted: true });
  }
}

export default withRouter(Sidebar);
