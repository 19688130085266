import React, { useState, useEffect, useContext } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { format as formatDate, getYear, getMonth, getDaysInMonth } from "date-fns";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const EmployeeAttendance = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [events, setEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(getMonth(new Date()) + 1); 
  const [currentYear, setCurrentYear] = useState(getYear(new Date()));
  const [holidays, setHolidays] = useState([]);
  const { password, username } = billpunchbasicauth;

  const getEmployeeAttendance = async (year, month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          month: month.toString(),
          year: year.toString(),
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };
  
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeAttendanceDetails`,
        requestOptions
      );
      const data = await res.json();
  
      const totalDays = getDaysInMonth(new Date(year, month - 1)); 
      const allDates = Array.from({ length: totalDays }, (_, i) => new Date(year, month - 1, i + 1));
  
      if (data.status === "0" && data.data) {
        const apiDates = data.data.map(item => new Date(item.attendance_date).toDateString());
        
        const mappedEvents = data.data.map((item) => {
          let title = "";
          if (item.type === "SL") {
            title = "Sick Leave (SL)";
          } else if (item.type === "PL") {
            title = "Paid Leave (PL)";
          } else if (item.type === "HalfDay") {
            title = "Half Day";
          } else if (item.attendance_status === "Y") {
            title = "Present";
          } else {
            title = "Absent";
          }
          return {
            title,
            start: new Date(item.attendance_date),
            end: new Date(item.attendance_date),
            allDay: true,
            status: item.attendance_status,
            type: item.type,
          };
        });
  
        const absentDates = allDates.filter(
          date => !apiDates.includes(date.toDateString())
        ).map(date => ({
          title: "Absent",
          start: date,
          end: date,
          allDay: true,
          status: "N", 
        }));
  
        setEvents([...mappedEvents, ...absentDates]); 
      }
  
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };
  

  const getHolidays = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(`${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmHoliday`, requestOptions);
      const data = await res.json();
      if (data.status === "0" && data.data) {
        const mappedHolidays = data.data.map(holiday => {
          const [day, month, year] = holiday.datee.split('/');
          const startDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
          return {
            title: holiday.holiday,
            start: startDate,
            end: startDate, 
            allDay: true,
            holiday: true 
          };
        });

        setHolidays(mappedHolidays);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getEmployeeAttendance(currentYear, currentMonth);
    getHolidays();
  }, [currentYear, currentMonth]);

  const handleNavigate = (date) => {
    setCurrentMonth(getMonth(date) + 1); 
    setCurrentYear(getYear(date));
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = '#3174ad'; 

    if (event.holiday) {
      backgroundColor = 'green';
    } else if (event.status === 'N') {
      backgroundColor = 'red'; 
    } else if (event.type === 'HalfDay') {
      backgroundColor = 'orange';
    } else if (event.type === 'SL') {
      backgroundColor = '#5D8736';
    } else if (event.type === 'PL') {
      backgroundColor = '#5D8736';
    }

    let style = {
      backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: '#fff',
      border: '0',
      display: 'block',
    };
    return {
      style: style
    };
  };

  return (
    <div className="p-4">
      <Calendar
        localizer={localizer}
        events={[...events, ...holidays]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        onNavigate={handleNavigate}
        defaultView="month"
        eventPropGetter={eventStyleGetter} 
      />
    </div>
  );
};

export default EmployeeAttendance;
