import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Link } from "react-router-dom";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import {
  MdAccountBalanceWallet,
  MdOutlineCrisisAlert,
  MdWifiCalling,
} from "react-icons/md";
import { FaComputer, FaSquareTwitter, FaUserGroup } from "react-icons/fa6";
import {
  FaDigitalTachograph,
  FaFacebook,
  FaHourglassEnd,
  FaHourglassStart,
  FaInstagramSquare,
  FaLinkedin,
  FaMoneyBill,
} from "react-icons/fa";
import { CiSearch } from 'react-icons/ci'; 
import { BsMotherboard } from "react-icons/bs";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { IoLogoWhatsapp } from "react-icons/io";
import { billpunchbasicauth } from "../../utils/Credentials";
const Firmdashboard = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [Project, setProject] = useState([]);
  const { password , username } = billpunchbasicauth;
 
  const [regularInternData, setRegularInternData] = useState({});
  const [departmentCounts, setDepartmentCounts] = useState({});
  const Firms = JSON.parse(sessionStorage.getItem("Firms"));
  console.log(Firms);
  const getEmployeeList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({ firmId: Firms.pk_firmid });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const result = await res.json();
      if (result.status === "0" && result.data) {
        const counts = result.data.reduce((acc, curr) => {
          const dept = curr.department;
          acc[dept] = acc[dept] ? acc[dept] + 1 : 1;
          return acc;
        }, {});
        setData(result.data);
        setDepartmentCounts(counts);

        // Calculate regular and intern counts
        const regularCount = result.data.filter(
          (employee) => employee.emp_type === "Regular"
        ).length;
        const internCount = result.data.filter(
          (employee) => employee.emp_type === "Intern"
        ).length;
        setRegularInternData({ regularCount, internCount });
        setTotalEmployees(result.data.length);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };

  const getProjects = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        firmid: Firms.pk_firmid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setProject(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const [social, setsocial] = useState([]);
  const Getquicklinks = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        firmid: Firms.pk_firmid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetSuperAdminfirmSocials`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setsocial(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const [selectedMonth, setSelectedMonth] = useState("");
  const months = [
    { value: "January", name: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
  ];
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const [dashboard, setDashboard] = useState(null);
  useEffect(() => {
    const getEmployeegenerateddashboard = async () => {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
        const raw = JSON.stringify({
          firmid: user.firm_id,
          month: selectedMonth,
          
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetPayoutDashboard`,
          requestOptions
        );
        const responseData = await res.json();

        if (responseData.status === "0") {
          const dashboardData = responseData.data[0] || {};
          setDashboard(dashboardData);
        } else {
          setDashboard({ yourDataProperty: 0 });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };

    getEmployeegenerateddashboard();
  }, [selectedMonth]);

  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
        display: true,
        align: "center",
        anchor: "center",
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          size: "16",
          weight: "bold",
        },
      },
    },
    legend: {
      display: false,
    },
  };

  const pieData = {
    labels: ["Total Generated", "Total Processed", "Total Unprocessed"],
    datasets: [
      {
        data: [
          parseFloat(dashboard?.totalSalary) || 0,
          parseFloat(dashboard?.totalProcessed) || 0,
          parseFloat(dashboard?.totalUnprocessed) || 0,
        ],
        backgroundColor: ["#FF8E8F", "#6196A6", "#7469B6"],
      },
    ],
  };
  const [dashboard1, setDashboard1] = useState(null);
  useEffect(() => {
    const getEmployeegenerateddashboard = async () => {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
        const raw = JSON.stringify({
          month: selectedMonth,
          firmid: user.firm_id,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFixedExpenseDashboard`,
          requestOptions
        );
        const responseData = await res.json();

        if (responseData.status === "0") {
          const dashboardData = responseData.data[0] || {};
          setDashboard1(dashboardData);
        } else {
          setDashboard1({ yourDataProperty: 0 });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };

    getEmployeegenerateddashboard();
  }, [selectedMonth]);

  const pieOptions1 = {
    plugins: {
      datalabels: {
        color: "#fff",
        display: true,
        align: "center",
        anchor: "center",
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          size: "16",
          weight: "bold",
        },
      },
    },
    legend: {
      display: false,
    },
  };

  const pieData1 = {
    labels: ["Total Generated", "Total Processed", "Total Unprocessed"],
    datasets: [
      {
        data: [
          parseFloat(dashboard1?.total_generated) || 0,
          parseFloat(dashboard1?.processed) || 0,
          parseFloat(dashboard1?.unprocessed) || 0,
        ],
        backgroundColor: ["#A3D8FF", "#94FFD8", "#7469B6"],
      },
    ],
  };
  useEffect(() => {
    getEmployeeList();
    getProjects();
    Getquicklinks();
  }, []);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalEmployees, setTotalEmployees] = useState(0);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter((employee) =>
    `${employee.firstname} ${employee.lastname}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <div className="p-2" style={{ background: "#f2f4f2" }}>
        <div className="row mt-2">
          <h5 className="col">{Firms.firm_name}</h5>
          <div className="col d-flex justify-content-end">
            {" "}
            <Link to="/retailer/Superadmin_dashboard">Back</Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
          <div className="card p-2">
              <div className="row">
                <div className="col-8">
                  {" "}
                  <FaUserGroup
                    style={{ fontSize: "2rem", color: "#5BBCFF" }}
                  />{" "}
                  <p
                    className="m-0"
                    style={{ fontSize: ".9rem", fontWeight: "600" }}
                  >
                   Total Employee
                  </p>
                </div>
                <div className="col">
                <h4>{totalEmployees}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card p-2">
              <div className="row">
                <div className="col">
                  <FaComputer style={{ fontSize: "2rem", color: "#41B06E" }} />
                  <p
                    className="m-0"
                    style={{ fontSize: ".9rem", fontWeight: "600" }}
                  >
                    IT Employee
                  </p>
                </div>
                <div className="col">
                  <h4>{departmentCounts["IT"] || 0}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card p-2">
              <div className="row">
                <div className="col-8">
                  {" "}
                  <MdOutlineCrisisAlert
                    style={{ fontSize: "2rem", color: "#5BBCFF" }}
                  />{" "}
                  <p
                    className="m-0"
                    style={{ fontSize: ".9rem", fontWeight: "600" }}
                  >
                    Sales Employee
                  </p>
                </div>
                <div className="col">
                  <h4>{departmentCounts["Sales"] || 0}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card p-2">
              <div className="row">
                <div className="col-8">
                  {" "}
                  <FaDigitalTachograph
                    style={{ fontSize: "2rem", color: "#135D66" }}
                  />{" "}
                  <p
                    className="m-0"
                    style={{ fontSize: ".9rem", fontWeight: "600" }}
                  >
                    {" "}
                    Digital Marketing
                  </p>
                </div>
                <div className="col">
                  <h4>{departmentCounts["Digital Marketing"] || 0}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card p-2">
              <div className="row">
                <div className="col-8">
                  {" "}
                  <MdWifiCalling
                    style={{ fontSize: "2rem", color: "#DC6B19" }}
                  />{" "}
                  <p
                    className="m-0"
                    style={{ fontSize: ".9rem", fontWeight: "600" }}
                  >
                    {" "}
                    Costumer Support
                  </p>
                </div>
                <div className="col">
                  <h4>{departmentCounts["Customer Support"] || 0}</h4>
                </div>
              </div>
            </div>
          </div>
      
   
        </div>
        <div className="row ">
          <div className="col-md-3 col">
            <h6>Employees</h6>
            <div
              className="card mt-md-3 p-2 custom-scrollbaractivity"
              style={{ height: "65vh", overflow: "auto" }}
            >
            <div className="input-group mb-3" style={{ position: 'relative' }}>
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control"
          style={{ padding: '10px 30px 10px 10px', fontSize: '1rem' }}
        />
        <div className="input-group-append" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
          <span className="input-group-text bg-transparent border-0">
            <CiSearch size={20} />
          </span>
        </div>
      </div>
              {filteredData.length === 0 ? (
                <p
                  className="d-flex justify-content-center"
                  style={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  No data found
                </p>
              ) : (
                filteredData.map((employee) => (
                  <div key={employee.empid}>
                    <p>
                      <span
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "2rem",
                          height: "2rem",
                          fontSize: "1rem",
                          fontWeight: "600",
                          background: "cadetblue",
                          color: "white",
                          borderRadius: "50%",
                        }}
                      >
                        {employee.firstname[0].toUpperCase()}
                      </span>{" "}
                      &nbsp;
                      <span style={{ fontSize: ".8rem", fontWeight: "600" }}>
                        {employee.firstname} {employee.lastname}
                      </span>
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="col-md-3 col ">
            <h6>Project</h6>
            <div
              className="custom-scrollbaractivity mt-md-3"
              style={{ height: "65vh", overflow: "auto" }}
            >
              {" "}
              {Project.length === 0 ? (
                <p
                  className="d-flex justify-content-center"
                  style={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  No data found
                </p>
              ) : (
                Project.map((project) => (
                  <div className="card p-2 mb-2 " key={project.pk_project_id}>
                    <p className="m-0">
                      {" "}
                      <span
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "2rem",
                          height: "2rem",
                          fontSize: "1rem",
                          fontWeight: "600",
                          background: "#874CCC",
                          color: "white",
                          borderRadius: "50%",
                        }}
                      >
                        {project.project_name[0].toUpperCase()}
                      </span>{" "}
                      &nbsp;
                      <span
                        className="firm-dashboard-zigzag"
                        style={{ fontSize: ".8rem", fontWeight: "600" }}
                      >
                        {project.project_name}
                      </span>
                      <br />
                      <div className="row">
                        <div className="col">
                          <p
                            style={{ fontSize: ".7rem", fontWeight: "700" }}
                            className="m-0"
                          >
                            <FaHourglassStart style={{ color: "#5BBCFF" }} />
                            Start Date
                          </p>
                          <p
                            className="m-0"
                            style={{ fontSize: ".7rem", fontWeight: "700" }}
                          >
                            {new Date(
                              project.project_startdate
                            ).toLocaleDateString() || "NA"}
                          </p>
                        </div>
                        <div className="col">
                          <p
                            style={{ fontSize: ".7rem", fontWeight: "700" }}
                            className="m-0"
                          >
                            <FaHourglassEnd style={{ color: "#5BBCFF" }} /> End
                            Date
                          </p>
                          <p
                            className="m-0"
                            style={{ fontSize: ".7rem", fontWeight: "700" }}
                          >
                            {" "}
                            {new Date(
                              project.project_enddate
                            ).toLocaleDateString() || "NA"}{" "}
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="col-md-3 col ">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h6>Salary Payout</h6>
              <select
                style={{
                  width: "5rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              className="card mt-2 p-2 text-center"
              style={{ border: "1px dotted grey" }}
            >
              <div>
                {" "}
                <FaMoneyBill style={{ color: "teal", fontSize: "2rem" }} />
              </div>
              <p
                className="m-0"
                style={{ fontSize: ".8rem", fontWeight: "600" }}
              >
                {" "}
                Total Generated
              </p>

              <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                {parseFloat(dashboard?.totalSalary) || 0}
              </span>
              <hr />
              <div>
                {" "}
                <FaMoneyBill style={{ color: "teal", fontSize: "2rem" }} />
              </div>
              <p
                className="m-0"
                style={{ fontSize: ".8rem", fontWeight: "600" }}
              >
                {" "}
                Total Processed
              </p>

              <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                {parseFloat(dashboard?.totalProcessed) || 0}
              </span>
              <hr />
              <div>
                {" "}
                <FaMoneyBill style={{ color: "teal", fontSize: "2rem" }} />
              </div>
              <p
                className="m-0"
                style={{ fontSize: ".8rem", fontWeight: "600" }}
              >
                {" "}
                Total Unprocessed
              </p>

              <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                {parseFloat(dashboard?.totalUnprocessed) || 0}
              </span>
            </div>
          </div>
          <div className="col-md-3 col">
            <h6>Quick Links</h6>
            <div
              className="custom-scrollbaractivity"
              style={{ height: "40vh", overflow: "auto" }}
            >
              {social.length === 0 ? (
                <p
                  className="d-flex justify-content-center"
                  style={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  No quick links available
                </p>
              ) : (
                // Map through the social state array
                social.map((link) => (
                  <div className="card mt-md-3 p-2 mb-2" key={link.pk_linkid}>
                    <span>
                      <img
                        src={link.social_image_url}
                        alt={link.social_name}
                        style={{ width: "2rem", marginRight: "0.5rem" }}
                      />
                      &nbsp;{" "}
                      <a target="_blank" href={link.social_links}>
                        {link.social_name}
                      </a>
                    </span>
                  </div>
                ))
              )}
            </div>
            <h6>Access Management</h6>
            <div className="card  p-2 mb-2">
              <Link to="/retailer/Firmd_access" className="p-1">
                <p className="p-1"
                  style={{
                    backgroundColor: "rgba(43, 193, 85, 0.1)",
                    color: "#2bc155",
                    border: "1px solid #2bc155",
                    borderRadius: ".3rem",
                    width: "13rem",
                    fontSize: ".9rem",
                    fontWeight: "600",
                  }}
                >
                  Manage Firm Access here <TbPlayerTrackNextFilled />
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Firmdashboard;
