import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { MdPeople } from "react-icons/md";
import { GlobalContext } from "../../Context/GlobalState";
import { FaMoneyBill, FaUser, FaBirthdayCake } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Pie, Bar } from "react-chartjs-2";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import {
  FaPlusCircle,
  FaDesktop,
  FaChartLine,
  FaHeadset,
  FaCog,
  FaEllipsisH,
  FaBullhorn,
  FaUsers,
  FaMoneyBillAlt,
  FaUserEdit,
} from "react-icons/fa"; // Import the necessary icon components

import { Link } from "react-router-dom";
import { AdddepartmentHrmodal } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Row } from "react-bootstrap";
const HRDashboard = () => {
  const CakeIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 8a9 9 0 0 1 18 0"></path>
      <path d="M3 8c0 5-1 9-1 9s-1.5-1.5-1-9a1 1 0 0 1 2 0z"></path>
      <path d="M21 8c0 5 1 9 1 9s1.5-1.5 1-9a1 1 0 0 0-2 0z"></path>
      <path d="M9 23v-2a2 2 0 0 1 4 0v2"></path>
    </svg>
  );

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const { password, username } = billpunchbasicauth;
  const [showcategorymodal, setshowcategorymodal] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [holidaydata, setholidaydata] = useState([]);
  const [employee, setemployee] = useState([]);
  const [BarData, setBarData] = useState([]);
  const [employeeCounts, setEmployeeCounts] = useState([]);
  const [employeebirth, setemployeebirth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [dashboard, setDashboard] = useState(null);
  const months = [
    { value: "January", name: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
  ];

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  // const handleMonthChange = (event) => {
  //   const selectedMonth = event.target.value;
  //   setSelectedMonth(selectedMonth);
  // };
  useEffect(() => {
    const getEmployeegenerateddashboard = async () => {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic" + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          month: selectedMonth,
          firmid: user.firm_id,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFixedExpenseDashboard`,
          requestOptions
        );
        const responseData = await res.json();

        if (responseData.status === "0") {
          const dashboardData = responseData?.data[0] || {};
          setDashboard(dashboardData);
        } else {
          setDashboard({ yourDataProperty: 0 });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };

    getEmployeegenerateddashboard();
  }, [selectedMonth]);
  const categoryIcons = {
    IT: <FaDesktop />,
    Sales: <FaChartLine />,
    Support: <FaHeadset />,
    Operations: <FaCog />,
    Other: <FaEllipsisH />,
    "Digital Marketing": <FaBullhorn />,
    "Customer Support": <FaUsers />,
    Accounts: <FaMoneyBillAlt />,
    "UI & UX": <FaUserEdit />,
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ firmid: user.firm_id }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getHolidaydata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmHoliday`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setholidaydata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeCount`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployee(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getEmployeebirthday = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployeebirth(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getEmployeeCount = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeCountDash`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployeeCounts(data.data[0]); // Assuming there's only one object in the data array
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    const fetchedData = [
      { cat1: "IT" },
      { cat1: "Sales" },
      { cat1: "Support" },
      { cat1: "Operations" },
      { cat1: "Other" },
      { cat1: "Digital Marketing" },
      { cat1: "Customer Support" },
      { cat1: "Accounts" },
      { cat1: "UI & UX" },
      // Add more data objects as needed
    ];
    setDepartmentData(fetchedData);
  }, []);
  const openaddcategorymodal = () => {
    setshowcategorymodal(true);
  };
  const closeaddcategorymodal = () => {
    setshowcategorymodal(false);
  };

  const labelsMap = {
    it_employees: "IT",
    sales_employees: "Sales",
    digital_employees: "Digital",
    customer_support: "Customer Support",
    accounts: "Accounts",
    other: "Other",
  };

  const chartLabels = Object.keys(employeeCounts).map((key) => labelsMap[key]);
  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        data: Object.values(employeeCounts),
        backgroundColor: [
          "#9AD0C2",
          "#41C9E2",
          "#FEC7B4",
          "#B0C5A4",
          "#BFEA7C",
          "#FFF67E",
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false, // Hide legend labels
    },
    tooltips: {
      enabled: true, // Enable tooltips
    },
  };

  const barOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  const currentMonth = new Date().getMonth() + 1; // JavaScript months are zero-based, so we add 1
  const filteredBirthdays = employeebirth.filter((birth) => {
    const birthMonth = new Date(birth.dob).getMonth() + 1;
    return birthMonth === currentMonth;
  });
  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const GetPayoutMonthly = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        month: "0",
        year: "2025",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetPayoutDashboard`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0") {
        const formattedData = {
          labels: [],
          datasets: [
            {
              label: "Total Intern Salary",
              data: [],
              backgroundColor: "rgba(54, 162, 235, 1)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
            {
              label: "Total Full Time Salary",
              data: [],
              backgroundColor: "rgba(54, 233, 235, 19)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        };

        responseData.data.forEach((monthData) => {
          formattedData.labels.push(monthData.month);
          formattedData.datasets[0].data.push(
            parseFloat(monthData.totalInternSalary)
          );
          formattedData.datasets[1].data.push(
            parseFloat(monthData.totalFullTimeSalary)
          );
        });

        setBarData(formattedData);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getDepartmentList();
    getHolidaydata();
    getEmployee();
    getEmployeebirthday();
    getEmployeeCount();
  }, []);
  useEffect(() => {
    GetPayoutMonthly();
  }, [selectedYear]);
  const formatDateWithoutYear = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
    });
  };
  return (
    <div className="m-3">
      <AdddepartmentHrmodal
        show={showcategorymodal}
        hide={closeaddcategorymodal}
        getDepartmentList={getDepartmentList}
      />
      <div className="row m-0 p-0 " style={{ background: "RGB(93,136,228)" }}>
        <div className="col">
          <h4 className="mt-1" style={{ color: "#fff" }}>
            Hi ,{user.username}
          </h4>
          <p style={{ color: "#fff", fontSize: "1rem" }}>
            Track and manage your team here!
          </p>
        </div>
        {/* <div className="col d-flex justify-content-end align-item-center">
        <h5 className="mt-1" style={{ color: "#fff" }}> {user.mobile}</h5> 
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-3 col-6">
          <div
            className="card m-0 p-2 text-center"
            style={{
              border: "1px dotted grey",
              height: "55vh",
              overflow: "hidden",
            }}
          >
            <h6 className="text-center">Expense Payout</h6>
            <div
              className="scrollable-content"
              style={{
                height: "100%",
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "transparent transparent",
              }}
            >
              <div className="row p-0 mb-3">
                <h6 className="col">{selectedMonth}</h6>
                <div className="col">
                  <select
                    style={{
                      width: "5rem",
                      height: "1.5rem",
                      borderRadius: ".4rem",
                      border: "1px #ced4da solid",
                    }}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  >
                    {months.map((month) => (
                      <option key={month?.value} value={month?.value}>
                        {month?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <FaMoneyBill style={{ color: "teal", fontSize: "1.5rem" }} />
              </div>
              <div>Total Generated</div>
              <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                {parseFloat(dashboard?.total_generated) || 0}
              </span>
              <hr className="m-1" />
              <div>
                <FaMoneyBill style={{ color: "teal", fontSize: "1.5rem" }} />
              </div>
              <div>Total Processed</div>
              <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                {parseFloat(dashboard?.processed) || 0}
              </span>
              <hr className="m-1" />
              <div>
                <FaMoneyBill style={{ color: "teal", fontSize: "1.5rem" }} />
              </div>
              <div>Total Unprocessed</div>
              <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                {parseFloat(dashboard?.unprocessed) || 0}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 d-flex flex-column align-items-center">
          <div className="m-0 p-2 mb-1">
            <h5 className="text-center">Employees</h5>
          </div>
          <div className="d-flex justify-content-center">
            <div style={{ width: "200px", height: "200px" }}>
              <Pie data={chartData} options={options} />
            </div>
          </div>
        </div>

        <div className="col-md-6 pt-md-0 pt-3 col">
          <div
            className="card m-0 p-0  d-flex flex-column"
            style={{ border: "1px dotted grey", height: "50vh" }}
          >
            <Row>
              <h6 className="col  pt-1">Salary Payout</h6>
              <div className=" col d-flex justify-content-end ">
                <select
                  value={selectedYear}
                  onChange={handleYearChange}
                  className="form-select w-25"
                  style={{ textAlign: "center" }}
                >
                  {[...Array(10).keys()].map((offset) => {
                    const year = new Date().getFullYear() - offset;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Row>
            <div style={{ flex: 1, position: "relative" }}>
              {BarData &&
              BarData.datasets &&
              BarData.datasets.length > 0 &&
              BarData.datasets[0].data.length > 0 ? (
                <Bar data={BarData} options={barOptions} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  No data found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 col-6">
          <div className="card m-0 p-2" style={{ background: "#fff" }}>
            <div className="row ">
              <h6 className="col p-1"> Department</h6>
              <div className="col p-1 d-flex justify-content-end mr-3 ">
                <Link onClick={openaddcategorymodal}>
                  {" "}
                  <FaPlusCircle style={{ color: "#0047AB" }} />
                </Link>
              </div>
            </div>
            <ul className="list-group">
              {departmentData.map((category, index) => (
                <li
                  key={index}
                  className="list-group-item p-1"
                  style={{ border: "none" }}
                >
                  {" "}
                  {categoryIcons[category.cat1]} &nbsp;
                  <span>{category.cat1}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="card m-0 p-2" style={{ background: "#fff" }}>
            <div className="row m-0 p-0">
              <div className="col">
                <img
                  alt=""
                  style={{
                    height: "3rem",
                  }}
                  src={require("../../../assets/images/birth5.gif")}
                />{" "}
              </div>
              <h6 className="col-8 ">
                {" "}
                <img
                  alt=""
                  style={{
                    height: "8vh",
                  }}
                  src={require("../../../assets/images/birth4.png")}
                />{" "}
              </h6>
            </div>
            <div
              className="card m-0 p-1"
              style={{ background: "#FFFDCB", border: "1px solid #FFC700" }}
            >
              <p
                className="m-0 p-0 d-flex justify-content-center"
                style={{ color: "#124076" }}
              >
                Take a minute and congratulate them on their special day!
                <img
                  alt=""
                  style={{
                    height: "1rem",
                  }}
                  src={require("../../../assets/images/birth1.png")}
                />{" "}
              </p>
            </div>
            <div>
              {filteredBirthdays.map((birth, index) => (
                <div key={index}>
                  <div className="row">
                    <div
                      className="col-5 d-flex justify-content-center"
                      style={{
                        color: "#FBA834",
                        fontSize: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      {birth.firstname}
                    </div>
                    <div
                      className=" col-5 d-flex justify-content-center"
                      style={{
                        color: "#673F69",
                        fontSize: ".9rem",
                        fontWeight: "600",
                      }}
                    >
                      <span>{formatDateWithoutYear(birth.dob)}</span>
                    </div>
                    <div className="col-2 m-0 p-0 d-flex justify-content-center">
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                        className="adminemp-tooltip-container"
                      >
                        <a
                          href={`mailto:${birth.work_email}?subject=Warm%20Birthday%20Wishes%20to%20${birth.firstname}&body=Dear%20${birth.firstname},%0D%0A%0D%0AHappy%20birthday!%20May%20your%20special%20day%20be%20filled%20with%20joy%20and%20happiness.%20Wishing%20you%20all%20the%20best%20today%20and%20always.%0D%0A%0D%0AWarm%20regards,%0D%0A[Your%20Name]%0D%0A[Your%20Position]%0D%0A[Company%20Name]`}
                          className=""
                        >
                          <IoMdMail style={{ color: "red" }} />
                        </a>
                        <span
                          className="adminemp-tooltip-text p-1"
                          style={{ fontSize: ".8rem" }}
                        >
                          {birth.work_email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col">
          <div
            className="table-responsive"
            style={{
              borderRadius: ".4em",
              border: "1px solid lightgrey",
              height: "56vh",
            }}
          >
            <table className="table table-bordered m-0 p-0 ">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
              >
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Days
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Holiday
                  </th>
                </tr>
              </thead>
              <tbody>
                {holidaydata.map((item, index) => (
                  <tr
                    style={{ borderBottom: "1px solid lightgrey" }}
                    key={index}
                  >
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.date}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.day}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.holiday}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HRDashboard;
