import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import documentUploadIcon from "../../../assets/images/pms/document_upload.svg";
import {
  AdddepartmentHrmodal,
  DocumentModalAdmin,
  EditEmployeeModalAdmin,
} from "./Modal";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FaEllipsisV, FaUser } from "react-icons/fa";
import { ExportToExcel, excelMapping, now } from "../../utils/utilFuctions";
import { billpunchbasicauth } from "../../utils/Credentials";
import editIcon from "../../../assets/images/pms/Edit_icon.svg";
import Select from "react-select";
import deleteIcon from "../../../assets/images/pms/Delete_icon.svg";
import { Pie } from "react-chartjs-2";
const arr = [{ label: "Name", value: "Name" }];
export const columns = [
  {
    dataField: "firstname",
    text: "First Name",
  },
  {
    dataField: "lastname",
    text: "Last Name",
  },

  {
    dataField: "mobile",
    text: "Mobile",
  },
  {
    dataField: "designation",
    text: "Designation",
  },

  {
    dataField: "department",
    text: "Department ",
  },
  {
    dataField: "emp_type",
    text: "Employee Type ",
  },

  {
    dataField: "address",
    text: "Address ",
  },

  {
    dataField: "email",
    text: "Email ",
  },

  {
    dataField: "dob",
    text: "DOB ",
  },

  {
    dataField: "joiningdate",
    text: "joining Date ",
  },
  {
    dataField: "bankaccno",
    text: "Bank name",
  },
  {
    dataField: "bankifsc",
    text: "IFSC",
  },

  {
    dataField: " bankname",
    text: "Acount",
  },
  {
    dataField: "qualification",
    text: "Qualification",
  },
  {
    dataField: "age",
    text: "Age",
  },

  {
    dataField: "pan",
    text: "PAN",
  },
];
const EmployeeListAdmin = () => {
  const { password, username } = billpunchbasicauth;
  const [popoverStates, setPopoverStates] = useState({});
  const [desable, setdesable] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { accessData } = useContext(GlobalContext);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [department, setDepartment] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [docmodal, setdocmodal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formData, setFormData] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    mobile: "",
    email: "",
    address: "",
    designation: "",
    wphone: "",
    salary: "",
    date: "",
    aadharno: "",
    department: "",
    dob: "",
    panno: "",
    epf_no: "",
    joiningdate: "",
    bankname: "",
    bankifsc: "",
    bankaccno: "",
    emp_type: "",
    workemail: "",
    pan: "",
    qualification: "",
  });
  const [pieData, setPieData] = useState({
    labels: [
      "Total Employee",
      "IT Employee",
      "Sales Employee",
      "Support Employee",
    ],
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: ["red", "cornflowerblue", "blue", "green"],
      },
    ],
  });
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
        display: true,
        align: "center",
        anchor: "center",
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          size: "16",
          weight: "bold",
        },
      },
    },
    legend: {
      display: false,
    },
  };
  const [errors, setErrors] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    mobile: "",
    email: "",
    address: "",
    designation: "",
    salary: "",
    date: "",
    aadharno: "",
    department: "",
    dob: "",
    panno: "",
    epf_no: "",
    joiningdate: "",
    workemail: "",
    pan: "",
    qualification: "",
  });
  const validateForm = () => {
    console.log("Validating form...");
    const newErrors = {};
    const spaceRegex = /^\s+/;

    if (formData.firstname.trim() === "") {
      newErrors.firstname = "First Name is required";
    }

    if (!formData.lastname || formData.lastname.trim() === "") {
      newErrors.lastname = "Last Name is required";
    }

    if (!formData.mobile || formData.mobile.trim() === "") {
      newErrors.mobile = "Mobile number is required";
    }

    if (!formData.email || formData.email.trim() === "") {
      newErrors.email = "Email is required";
    }

    if (formData.address.trim() === "") {
      newErrors.address = "Address is required";
    }
    if (spaceRegex.test(formData.workemail)) {
      errors.workemail = "*Work email should not start with a space";
    } else if (formData.workemail.trim() === "") {
      errors.workemail = "*Please enter Work email";
    }

    if (spaceRegex.test(formData.pan)) {
      errors.pan = "*Pan should not start with a space";
    } else if (formData.pan.trim() === "") {
      errors.pan = "*Please enter Pan";
    }

    if (spaceRegex.test(formData.qualification)) {
      errors.qualification = "*Qualification should not start with a space";
    } else if (formData.qualification.trim() === "") {
      errors.qualification = "*Please enter Qualification";
    }

    if (!formData.joiningdate) {
      errors.joiningdate = "*Please select a valid Joining Date";
    }
    if (!formData.dob) {
      errors.dob = "*Please select a valid Date of Birth";
    }
    if (formData.department === "") {
      newErrors.department = "Department is required";
    }

    if (formData.aadharno === "") {
      newErrors.aadharno = "Aadhar Number is required";
    }

    if (formData.epf_no === "") {
      newErrors.epf_no = "EPF Number is required";
    }

    // if (!formData.joiningdate) {
    // newErrors.joiningdate = "Joining Date is required";
    // }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log("Calling API...");
      handleEditSubmit(selectedEmployee.empid);
      console.log("API call complete");
    }

    return Object.keys(newErrors).length === 0;
  };

  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const togglePopover = (empid) => {
    setPopoverStates((prevState) => ({
      ...prevState,
      [empid]: !prevState[empid],
    }));
  };

  const employeedisable = async (empid) => {
    // Show confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Once disabled, you will not be able to recover this employee!",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: true,
    })
      .then((confirmed) => {
        if (confirmed) {
          // User confirmed, proceed with API call
          setTransactionLoaderState(true);
          try {
            var myHeaders = new Headers();
            myHeaders.append(
              "Authorization",
              "Basic " +
                Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              empid: empid,
            });

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            return fetch(
              `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/DisableEmployee`,
              requestOptions
            );
          } catch (error) {
            console.log("error", error);
            swal(
              "Error",
              "An error occurred while disabling the employee.",
              "error"
            );
          } finally {
            setTransactionLoaderState(false);
          }
        } else {
          // User cancelled, do nothing
        }
      })
      .then(async (res) => {
        if (res) {
          const data = await res.json();
          if (data.status === "0") {
            setdesable(data.data);
            swal("Success", "Employee disabled successfully.", "success");
            getEmployeeList();
          } else {
            swal("Error", "Failed to disable employee.", "error");
            getEmployeeList();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        swal(
          "Error",
          "An error occurred while disabling the employee.",
          "error"
        );
      });
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };
  const openDocumentUploadModal = (id, firstname, lastname) => {
    sessionStorage.setItem("employeeName", `${firstname} ${lastname}`);

    setSelectedEmployeeId(id);
    setdocmodal(true);
  };

  const closeDocumentUploadModal = () => {
    setdocmodal(false);
  };
  const openEditModal = (id, empid) => {
    setSelectedEmployee(id);
    setSelectedEmployeeId(empid);

    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
    setSelectedEmployee(null);
  };

  const deleteEmployee = (empid) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Employee Details!",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        handleDelete(empid);
      }
    });
  };
  const handleDelete = async (empid) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empId: empid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/deleteEmployee `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        getEmployeeList();
        swal("Success", data.result, "success");
      } else {
        getEmployeeList();
        swal("Error", data.result, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      swal("Error", "An error occurred while deleting the tower.", "error");
    }
    setTransactionLoaderState(false);
  };

  const getEmployeeList = async () => {
    console.log(accessData);
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);

        const departmentCounts = {};
        data.data.forEach((employee) => {
          const department = employee.department;
          if (departmentCounts[department]) {
            departmentCounts[department]++;
          } else {
            departmentCounts[department] = 1;
          }
        });

        const labels = Object.keys(departmentCounts);
        const counts = Object.values(departmentCounts);

        setPieData({
          labels: labels,
          datasets: [
            {
              data: counts,
              backgroundColor: [
                "#9AD0C2",
                "#41C9E2",
                "#FEC7B4",
                "#B0C5A4",
                "#BFEA7C",
                "#FFF67E",
                "#FFAF45",
              ],
            },
          ],
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const formatDateString = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const handleEditSubmit = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: selectedEmployeeId,

        firm_id: user.firm_id,
        firstname: formData.firstname,
        middlename: formData.middlename,
        lastname: formData.lastname,
        address: formData.address,
        mobile: formData.mobile,
        email: formData.email,
        designation: formData.designation,
        epf_no: formData.epf_no,
        department: formData.department,
        joiningdate: formatDateString(formData.joiningdate),
        emp_type: formData.emp_type,
        dob: formatDateString(formData.dob),
        bankname: formData.bankName,
        bankifsc: formData.ifscCode,
        bankaccno: formData.accountNumber,
        qualification: formData.qualification,
        pan: formData.pan,
        work_email: formData.workemail,
      });

      console.log(formData.id, "adiii");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/editEmployee `,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        getEmployeeList();
        swal("Success", data.msg, "success");
        closeEditModal();
      } else {
        getEmployeeList();
        closeEditModal();
        swal("Error", data.msg, "warning");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    getEmployeeList();
    getDepartmentList();
    //getAccessData();
  }, []);
  const [showcategorymodal, setshowcategorymodal] = useState(false);
  const openaddcategorymodal = () => {
    setshowcategorymodal(true);
  };
  const closeaddcategorymodal = () => {
    setshowcategorymodal(false);
  };
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayImageUrl, setOverlayImageUrl] = useState(null);

  const handleImageClick = (imageUrl) => {
    setOverlayImageUrl(imageUrl);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setOverlayImageUrl(null);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = data.filter((item) => {
    const fullName =
      `${item.firstname} ${item.lastname} ${item.designation} ${item.mobile} ${item.emptype} ${item.department}`.toLowerCase();
    const departmentFilterMatch =
      department === "" || item.department === department;
    const searchMatch =
      !searchQuery || fullName.includes(searchQuery.toLowerCase());
    return departmentFilterMatch && searchMatch;
  });
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const paginationOptions = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "250", value: 250 },
  ];
  const handleItemsPerPageChange = (selectedOption) => {
    setItemsPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  const selectOptions = paginationOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  return (
    <>
      {showOverlay && overlayImageUrl && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={handleCloseOverlay}
        >
          <img
            src={overlayImageUrl}
            alt="Profile Overlay"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "10px",
            }}
          />
        </div>
      )}
      <AdddepartmentHrmodal
        show={showcategorymodal}
        hide={closeaddcategorymodal}
        getDepartmentList={getDepartmentList}
      />
      <div
        className="m-3"
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 py-1">
          <div
            className="card p-3 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row">
              <h5 className="col d-flex align-items-center">
                Employee Management
              </h5>

              <div className="col d-flex align-items-center justify-content-end">
                {" "}
                <div
                  className="d-flex "
                  style={{
                    cursor: "pointer",
                    border: "1px solid",
                    height: "2.5rem",
                    width: "11rem",
                    backgroundColor: "rgba(43, 193, 85, 0.1)",
                    color: "rgba(43, 193, 85, 1)",
                    bordercolor: "rgba(43, 193, 85, 1)",
                    borderRadius: ".4rem",
                  }}
                  onClick={() => {
                    ExportToExcel(
                      `employee_ledger${now()}`,
                      excelMapping(data, columns)
                    );
                  }}
                >
                  <img
                    alt=""
                    src={require("../../../assets/images/exportexcel.png")}
                    style={{ width: "1.6rem", marginLeft: "15px" }}
                  />
                  <strong
                    className="d-flex align-items-center"
                    style={{ marginLeft: "2px" }}
                  >
                    Export to Excel
                  </strong>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-md-2 col-4 card mr-3 "
                style={{
                  border: "1px solid red",
                  borderRadius: ".4rem",
                  height: "fit-content",
                  boxShadow: "0 0 10px rgba(255, 0, 0, 0.5)",
                }}
              >
                {" "}
                <img
                  alt=""
                  style={{
                    height: "2rem",
                  }}
                  src={require("../../../assets/images/pms/salary_report.svg")}
                />
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: ".9rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Total Employee
                </p>
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  {data.length}
                </p>
              </div>
              <div
                className="col-md-2 col-4 card mr-3 "
                style={{
                  border: "1px solid cornflowerblue",
                  borderRadius: ".4rem",
                  height: "fit-content",
                  boxShadow: "0 0 10px rgba(100, 149, 237, 0.5)",
                }}
              >
                <img
                  alt=""
                  style={{
                    height: "2rem",
                  }}
                  src={require("../../../assets/images/pms/salary_report.svg")}
                />
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: ".9rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  IT Employee
                </p>
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    color: "cornflowerblue",
                  }}
                >
                  {
                    data.filter((employee) => employee.department === "IT")
                      .length
                  }
                </p>
              </div>
              <div
                className="col-md-2 col-4 card mr-3 "
                style={{
                  border: "1px solid blue",
                  borderRadius: ".4rem",
                  height: "fit-content",
                  boxShadow: "0 0 10px rgba(0, 0, 255, 0.5)",
                }}
              >
                <img
                  alt=""
                  style={{
                    height: "2rem",
                  }}
                  src={require("../../../assets/images/pms/salary_report.svg")}
                />{" "}
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: ".9rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Sales Employee
                </p>
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    color: "blue",
                  }}
                >
                  {
                    data.filter((employee) => employee.department === "Sales")
                      .length
                  }
                </p>
              </div>
              <div
                className="col-md-2 col-4 card mr-3 "
                style={{
                  border: "1px solid green",
                  borderRadius: ".4rem",
                  height: "fit-content",
                  boxShadow: "0 0 10px rgba(0, 128, 0, 0.5)",
                }}
              >
                <img
                  alt=""
                  style={{
                    height: "2rem",
                  }}
                  src={require("../../../assets/images/pms/salary_report.svg")}
                />{" "}
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: ".9rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Support Employee
                </p>
                <p
                  classname="d-flex justify-content-center"
                  style={{
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  {
                    data.filter((employee) => employee.department === "Support")
                      .length
                  }
                </p>
              </div>
              <div
                className="col-md-3 col-5 card mr-md-3 m-0 p-0"
                style={{ borderRadius: ".4rem" }}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "100%" }}
                >
                  <Pie data={pieData} options={pieOptions} />
                </div>
              </div>
            </div>
          </div>

          <div
            className="card p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem", border: "none" }}
          >
            <div className="row mt-md-3 mx-sm-2 mb-md-1 mb-sm-1">
              <div className="col-md-5 col-3">
                <h5
                  className="d-flex align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Employee List
                </h5>
              </div>
              <div className="col d-flex justify-content-end">
                <input
                  style={{
                    background: "#fff",
                    borderRadius: ".4rem",
                    width: "30% ",
                    border: "1px #ced4da solid",
                    paddingLeft: "10px",
                    height: "2rem",
                  }}
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <div className="col d-flex justify-content-end">
                  <select
                    style={{
                      // width: "9rem",
                      height: "2rem",
                      borderRadius: ".4rem",
                      border: "1px #ced4da solid",
                    }}
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                    }}
                  >
                    <option value="">All Department</option>
                    {departmentData.map((dept) => (
                      <option key={dept.id} value={dept.cat1}>
                        {dept.cat1}
                      </option>
                    ))}
                  </select>
                </div>
                <Link
                  onClick={openaddcategorymodal}
                  type="button"
                  className="btn btn-sm btn-primary d-flex justify-content-center"
                  style={{
                    color: "#fff",
                    height: "2rem",
                    border: "none",
                    borderRadius: ".4rem",
                    display: "flex",
                    alignItems: "center",
                    // width: "9rem",
                    fontSize: ".8rem",
                  }}
                >
                  Add Department
                </Link>
                {accessData &&
                  accessData.map(
                    (module) =>
                      module.data &&
                      module.data.map((role) =>
                        role.role_name === "Add Employee" ? (
                          role.access === "Y" ? (
                            <span
                              key={role.pk_role_id}
                              style={{ marginLeft: "10px" }}
                            >
                              <Link
                                to="/retailer/EmployeeFormAdmin"
                                type="button"
                                className="btn btn-sm btn-primary d-flex justify-content-center"
                                style={{
                                  color: "#fff",
                                  height: "2rem",
                                  border: "none",
                                  borderRadius: ".4rem",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "7rem",
                                  fontSize: ".8rem",
                                }}
                              >
                                Add Employee
                              </Link>
                            </span>
                          ) : (
                            <span
                              key={role.pk_role_id}
                              style={{ marginLeft: "10px" }}
                            >
                              <button
                                className="btn btn-sm btn-primary d-flex justify-content-center"
                                style={{
                                  color: "#fff",
                                  height: "2rem",
                                  border: "none",
                                  borderRadius: ".4rem",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "7rem",
                                  fontSize: ".8rem",
                                  backgroundColor: "gray",
                                  cursor: "not-allowed",
                                }}
                                disabled
                              >
                                Add Employee
                              </button>
                            </span>
                          )
                        ) : null
                      )
                  )}
              </div>
            </div>
            <hr className="p-0 mx-md-1 m-0 p-0" />
            <table
              className="m-md-2 mb-5 pb-5"
              style={{
                textAlign: "center",
                overflow: "",
              }}
            >
              <colgroup>
                <col style={{ width: "4%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <thead className="mx-md-2">
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Profile
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Emp Code
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Name
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Designation
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Department
                  </th>
                  {/* <th
 className="text-center"
 style={{
 color: "#000",
 background: "#fff",
 border: "none",
 }}
 >
 Employee Type
 </th> */}
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Mobile
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Qualification
                  </th>
                  {/* 
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    PAN
                  </th> */}
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {currentItems.length > 0 ? (
                  currentItems.map((item) => {
                    const fullName =
                      `${item.firstname} ${item.lastname} ${item.designation} ${item.mobile} ${item.emptype} ${item.department}`.toLowerCase();
                    if (
                      !searchQuery ||
                      fullName.includes(searchQuery.toLowerCase())
                    ) {
                      return (
                        <tr
                          key={item.id}
                          style={{ borderBottom: "1px solid #ced4da" }}
                        >
                          <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item.image_url ? (
                              <img
                                src={item.image_url}
                                alt="Profile"
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                                onClick={() => handleImageClick(item.image_url)}
                              />
                            ) : (
                              <FaUser
                                style={{ color: "teal", fontSize: "1rem" }}
                              />
                            )}
                          </td>
                          <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item.empcode}
                          </td>
                          <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item.firstname}
                            <br />
                            {item.lastname}
                          </td>
                          <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item.designation}
                          </td>
                          <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item.department}
                          </td>
                          <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item.mobile}
                          </td>
                          <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item.qualification}
                          </td>
                          {/* <td
                            className="text-center align-middle"
                            style={{
                              border: "none",
                              fontSize: ".8rem",
                              fontWeight: "700",
                            }}
                          >
                            {item?.pan?.replace(/.(?=.{3})/g, "*")}
                          </td> */}
                          <td
                            className="text-center align-middle"
                            style={{ border: "none", fontSize: "1rem" }}
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                                // marginRight: "5px",
                              }}
                              className="adminemp-tooltip-container"
                            >
                              <img
                                alt=""
                                src={documentUploadIcon}
                                onClick={() =>
                                  openDocumentUploadModal(
                                    item.empid,
                                    item.firstname,
                                    item.lastname
                                  )
                                }
                                className="adminemp-tooltip-icon"
                              />
                              <span className="adminemp-tooltip-text">
                                Upload Document
                              </span>
                            </div>

                            {accessData &&
                              accessData.map(
                                (module) =>
                                  module.data &&
                                  module.data.map((role) =>
                                    role.role_name === "Edit Employee" ? (
                                      role.access === "Y" ? (
                                        <span
                                          key={role.pk_role_id}
                                          style={{ marginLeft: "3px" }}
                                        >
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              marginRight: "3px",
                                            }}
                                            className="adminemp-tooltip-container"
                                          >
                                            <img
                                              alt=""
                                              src={editIcon}
                                              onClick={() =>
                                                openEditModal(item, item.empid)
                                              }
                                              style={{ height: "2.8rem" }}
                                              className="adminemp-tooltip-icon"
                                            />
                                            <span className="adminemp-tooltip-text">
                                              Edit Employee
                                            </span>
                                          </div>
                                        </span>
                                      ) : (
                                        <Link
                                          style={{
                                            marginRight: "3px",
                                            pointerEvents: "none",
                                            opacity: 0.5,
                                          }}
                                          className="tooltip-container"
                                        >
                                          <img
                                            alt=""
                                            src={require("../../../assets/images/pms/Edit_icon.svg")}
                                            onClick={() =>
                                              openEditModal(item, item.empid)
                                            }
                                            style={{ height: "2.8rem" }}
                                          />
                                        </Link>
                                      )
                                    ) : null
                                  )
                              )}

                            {accessData &&
                              accessData.map(
                                (module) =>
                                  module.data &&
                                  module.data.map((role) =>
                                    role.role_name === "Delete Employee" ? (
                                      role.access === "Y" ? (
                                        <span
                                          key={role.pk_role_id}
                                          // style={{ marginLeft: "10px" }}
                                        >
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              // marginRight: "5px",
                                            }}
                                            className="adminemp-tooltip-container"
                                          >
                                            <img
                                              alt=""
                                              src={deleteIcon}
                                              onClick={() =>
                                                deleteEmployee(item.empid)
                                              }
                                              style={{ height: "2.8rem" }}
                                              className="adminemp-tooltip-icon"
                                            />
                                            <span className="adminemp-tooltip-text">
                                              Delete Employee
                                            </span>
                                          </div>
                                        </span>
                                      ) : (
                                        <Link
                                          style={{
                                            marginRight: "5px",
                                            pointerEvents: "none",
                                            opacity: 0.5,
                                          }}
                                          className="tooltip-container"
                                        >
                                          <img
                                            alt=""
                                            src={require("../../../assets/images/pms/Delete_icon.svg")}
                                            onClick={() =>
                                              deleteEmployee(item.empid)
                                            }
                                            style={{ height: "2.8rem" }}
                                          />
                                        </Link>
                                      )
                                    ) : null
                                  )
                              )}

                            <span
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              {popoverStates[item.empid] && (
                                <>
                                  <div
                                    className="disablepop"
                                    style={{
                                      backgroundColor: "#ffffff",
                                      border: "1px solid #ced4da",
                                      borderRadius: ".4rem",
                                      padding: "8px",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      marginLeft: "-4rem",
                                      zIndex: 100,
                                      position: "absolute",
                                      width: "6rem",
                                    }}
                                  >
                                    {accessData &&
                                      accessData.map(
                                        (module) =>
                                          module.data &&
                                          module.data.map((role) =>
                                            role.role_name ===
                                            "Disable Employee" ? (
                                              role.access === "Y" ? (
                                                <span
                                                  key={role.pk_role_id}
                                                  style={{
                                                    marginLeft: "10px",
                                                    fontSize: ".8rem",
                                                  }}
                                                >
                                                  <Link
                                                    onClick={() =>
                                                      employeedisable(
                                                        item.empid
                                                      )
                                                    }
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                    className="tooltip-container"
                                                  >
                                                    Disable
                                                  </Link>
                                                </span>
                                              ) : (
                                                <Link
                                                  style={{
                                                    marginRight: "5px",
                                                    pointerEvents: "none",
                                                    opacity: 0.5,
                                                    fontSize: ".8rem",
                                                  }}
                                                  className="tooltip-container"
                                                >
                                                  Disable
                                                </Link>
                                              )
                                            ) : null
                                          )
                                      )}
                                    <hr className="m-0 p-0" />
                                    <a
                                      href={`https://billpunch.com/OfferLetter/pdfdownload.php?empid=${item.empid}`}
                                      style={{
                                        marginRight: "5px",
                                        fontSize: ".8rem",
                                      }}
                                      className="tooltip-container"
                                      download
                                    >
                                      Offer letter
                                    </a>

                                    {/* <hr /> */}

                                    {/* <h6>
                        <Link
                          onClick={() =>
                            opencredentialmodal(
                              item.empid,
                              item.email
                            )
                          }
                        >
                          Credential
                        </Link>
                      </h6> */}
                                  </div>
                                </>
                              )}
                            </span>
                            <FaEllipsisV
                              onClick={() => togglePopover(item.empid)}
                            />
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="text-center align-middle"
                      style={{ fontSize: "1rem", fontWeight: "700" }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="mt-3 mb-3 ">
              <label>Items per page:</label>
              <div style={{ width: "6rem" }}>
                <Select
                  options={selectOptions}
                  value={{ value: itemsPerPage, label: `${itemsPerPage}` }}
                  onChange={handleItemsPerPageChange}
                  style={{ width: "4rem" }}
                />
              </div>
            </div>
            <DocumentModalAdmin
              show={docmodal}
              onHide={closeDocumentUploadModal}
              selectedEmployeeId={selectedEmployeeId}
              getEmployeeList={getEmployeeList}
              data={setData}
            />

            <EditEmployeeModalAdmin
              show={isModalOpen}
              onHide={() => setIsModalOpen(false)}
              employeeData={selectedEmployee}
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              validateForm={validateForm}
              handleEditSubmit={handleEditSubmit}
              empid={selectedEmployeeId}
              getEmployeeList={getEmployeeList}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeListAdmin;
