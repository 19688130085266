import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { ViewBalanceleave } from "./SalaryTable";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AddBalanceleave } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
const Balanceleave = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const { password, username } = billpunchbasicauth;
  const [searchQuery, setSearchQuery] = useState("");

  const getbalanceleave = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: 0,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetLeaveBalance`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);
      } else {
        setData([]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    getbalanceleave();
  }, []);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = data.filter((item) =>
    item.fullname.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
   
      <div>
        <div className=" row mt-md-4 mt-sm-4 p-0 mt-sm-3">
          <h5 className="col mx-md-4 mx-sm-3 mb-sm-4 d-flex align-items-center ">
            {" "}
            Balance Leave of Employees
          </h5>

          <div className=" col mr-md-4 mr-sm-3 d-flex justify-content-end">
            <div className="px-2">
              <input
                className="form-control"
                style={{
                  background: "#fff",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                  paddingLeft: "10px",
                  height: "2rem",
                }}
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
           
          </div>
        </div>

        <div
          className=" p-0 mx-md-3"
          style={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <ViewBalanceleave
            data={filteredData}
            getbalanceleave={getbalanceleave}
          />
        </div>
      </div>
    </>
  );
};

export default Balanceleave;
